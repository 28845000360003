<template>
  <a-card :bordered="false" class="card-area">
    <div class="search">
      <!-- 搜索区域 -->
      <!--      <div class="items" style="flex: none">-->
      <!--        <div class="one-item">-->
      <!--          <a-input-->
      <!--              class="input"-->
      <!--              v-model="queryParams.keyword"-->
      <!--              placeholder="分类名称/描述"-->
      <!--              allowClear-->
      <!--          />-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="operator">
        <a-button type="primary" @click="search(1)">查询</a-button>
<!--        <a-button style="margin-left: 8px" @click="reset">重置</a-button>-->
      </div>
<!--      <a-button v-hasPermission="'level:add'" type="primary" @click="add" style="margin-left:20px;" icon="plus">-->
<!--        新增-->
<!--      </a-button>-->
    </div>
    <a-table
        ref="TableInfo"
        size="small"
        :bordered="true"
        :columns="columns"
        rowKey="level"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 1000 }"
    >
      <template slot="operation" slot-scope="text, record">
        <a-button type="primary" icon="edit" size="small" v-has-permission="'level:edit'" @click="modify(record)">
          修改
        </a-button>
      </template>
    </a-table>
    <modify-modal ref="modifyModal" @success="editSuccess"/>
  </a-card>
</template>
<script>
import {ListMixin} from "@/mixin/list-mixin";
import modifyModal from "./modifyUserLevelModal";

export default {
  name: "UserLevelList",
  mixins: [ListMixin],
  components: {modifyModal},
  data() {
    return {
      listGetUrl: "level/list",
      listMethod: "get",
      pagination:{
        pageSizeOptions: [ "100"],
        defaultPageSize: 100,
      }
    };
  },
  computed: {
    textColor() {
      return this.$store.state.setting.color;
    },
    columns() {
      let {sortedInfo, filteredInfo} = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: "级别",
          width: 80,
          align: "center",
          dataIndex: "level",
        },
        {
          title: "级别名称",
          align: "center",
          width: 200,
          dataIndex: "levelName",
        },

        {
          title: "需要积分",
          width: 120,
          align: "center",
          dataIndex: "needScore",
        },
        {
          title: "升级需要积分",
          width: 120,
          dataIndex: "upgradeScore",
          align: "center",
        },
        {
          title: "操作",
          width: 100,
          align: "center",
          dataIndex: "operation",
          scopedSlots: {customRender: "operation"},
        },
        {}
      ];
    },
  },

  mounted() {
    this.search();
  },
  methods: {
    editSuccess() {
      this.search();
    },
    add() {
      this.$refs.modifyModal.showModal(undefined, 'new');
    },
    modify(record) {
      this.$refs.modifyModal.showModal(record, 'update');
    },
  },
};
</script>

<style lang="less" scoped>
.textClick {
  // color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.img-list {
  display: flex;
  justify-content: center;
}
</style>
