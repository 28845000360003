<template>
  <a-modal
      v-drag-modal
      :title="title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :maskClosable="false"
      :width="400"
      @ok="handleOk"
      @cancel="visible = false"
  >
    <a-form-model ref="ruleForm" :model="form" :rules="rules">
      <a-form-model-item label="级别号" v-bind="formItemLayout">
        <a-input v-model="form.level" v-decorator="['level']" disabled placeholder="用户级别">
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="级别名称" v-bind="formItemLayout">
        <a-input v-model="form.levelName" v-decorator="['levelName']" placeholder="用户级别显示名称，最多6个字" :max-length="6">
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="需要积分" v-bind="formItemLayout">
        <a-input-number v-model="form.needScore" v-decorator="['needScore']" placeholder="需要积分">
        </a-input-number>
      </a-form-model-item>
      <a-form-model-item label="升级需要积分" v-bind="formItemLayout">
        <a-input-number v-model="form.upgradeScore" v-decorator="['upgradeScore']" placeholder="升到当前级别时需要积分">
        </a-input-number>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
export default {
  name: "ModifyDimensionModal",
  data() {
    return {
      formItemLayout: {
        labelCol: {span: 8},
        wrapperCol: {span: 14},
      },
      mode: 'new',
      title: "",
      visible: false,
      confirmLoading: false,
      form: {},
      rules: {
        levelName: [
          {
            required: true,
            message: "级别名称不能为空",
            trigger: "blur",
          },
        ],
        needScore: [
          {
            required: true,
            message: "需要积分不能为空",
            trigger: "blur",
          },
        ],
        upgradescore: [
          {
            required: true,
            message: "升级需要积分不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },

  methods: {
    showModal(record, mode) {
      this.mode = mode || 'new'
      this.visible = true;
      if (this.mode === 'update') {
        this.title = "修改用户级别：" + record.level;
        this.form = {
          ...record,
        };
      } else {
        this.title = "新增用户级别"
        this.form = {}
      }
    },
    handleOk() {
      this.$refs.ruleForm.validate((valid, values) => {
        if (valid) {
          this.confirmLoading = true;
          this.$postJson("level/save", this.form).then((res) => {
            this.visible = false;
            this.confirmLoading = false;
            this.$message.success("保存成功");
            this.$emit("success");
          });
        } else {
          this.confirmLoading = false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.ant-input-number {
  width: 100% !important;
}
</style>
